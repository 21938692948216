import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import cover7 from "./cover7.jpg"
import cover8 from "./cover8.jpg"
import TradingViewWidget from "./td";



export default function Tbot({ isLogged }) {
    const history = useHistory()
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" align="center" px={["0", "8%"]} pt={["0", "6"]} pb={["0", "60px"]}>
                <Flex w="100%" py="5" px={["3", "5"]} bgColor="#000" backgroundImage={cover7} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius={["0", "8px"]} justify="flex-end">
                    <Flex w={["100%", "70%"]} direction="column" px={["6", "10"]} bg={["rgba(0,0,0,.61)", "rgba(0,0,0,.71)"]} backdropFilter={["blur(1px)", "blur(5px)"]} mt={["12", "100px"]} py={["8", "12"]} color="#fff" borderRadius="8px" data-aos="fade-up">
                        <Text fontSize={["26px", "1.875rem"]} data-aos="fade-down" fontWeight="700">Auto Trading Bot</Text>
                        <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]}>BrixtenCove BOT are automated computer programs designed to run specific tasks with minimal human intervention. In the world of cryptocurrency trading, BrixtenCove BOT do the work of trading either one or multiple cryptocurrencies on one or multiple platforms automatically on behalf of the owner or user. They are used to automate trading strategies and applied for purposes of amplifying trading profits.</Text>
                        <Flex mt="7">
                            <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => {
                                history.push("/dashboard")
                                isLogged && Toast("Bot linking in process...")
                            }}>Link trading bot <Text ml="2"><i className="mdi mdi-robot"></i></Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


            <Flex w="100%" px={["8", "20%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000" fontSize={['13px', "15px"]}>
                <Text fontSize={["1.5rem", "1.875rem"]} textAlign="left" fontWeight="600">Why choose BrixtenCove BOT?</Text>
                <Flex direction="column" align="center" w="100%" justify="flex-start">
                    <Text color="#ffd200" w="100%" fontSize={["22px", "30px"]}><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i></Text>
                </Flex>
                <Text color="#6b7280" mt="2" textAlign="justify">MOST TIMES THE BOT TENDS TO CATCH EMERGENCY SIGNALS WHICH GOES INTO PROFIT IN THE SPACE OF SECONDS SO ONLY THOSE WHO LINKED THERE WALLET WILL PARTAKE FROM SUCH TRADE. THE BOT is recommended to increase trading efficiency and profitability. It also eliminates the possibility of human errors and improves trading speed.

                With BrixtenCove BOT, you can take advantage of crypto volatility, such that the bot pick profits even from the smallest price changes. They allow you to track markets efficiently and stay on top of the game.
                </Text>
                <Text w="100%" mt="7" h={["400px", "450px"]}>
                    <TradingViewWidget />
                </Text>
                <Text color="#6b7280" mt="7" textAlign="justify">
                BrixtenCove BOT pull information from multiple social sources, news sites, crypto market makers, and many other sources. This is why they employ AI and machine learning to decipher what news is making a social impact and which will probably highly influence market prices.

                THE AUTO TRADING BOT IS FOR EVERYONE BUT MAINLY FOR PEOPLE WHO DON’T HAVE TIME TO MONITOR THE MARKET DUE TO WORK OR OTHER ACTIVITIES BUT STILL WANT TO EARN FROM TRADING, THEY CAN JUST LINK THE BOT TO THERE WALLET AND IT TRADES AUTOMATICALLY FOR THEM.</Text>
                <Flex mt="7">
                    <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 28px" justify="center" align="center" onClick={() => {
                        history.push("/dashboard")
                        isLogged && Toast("Bot linking in process...")
                    }}>Link trading bot <Text ml="2"><i className="mdi mdi-robot"></i></Text></Flex>
                </Flex>
            </Flex>


        </Flex>
    )
}