import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner, Box } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile } from "./request";
import wallet from "./wallet.png"
import TradingViewWidget from "./td";



export default function D1({ bal1, bal3, bal2, brixtenData, setMenu3 }) {
    const history = useHistory()
    
    const [pageData, setPageData] = useState("")
    async function loadData() {
        const formData = new FormData()
        formData.append("uid", brixtenData?.uid)
        const res = await ApiFile("post", "loaduser.php", formData)
        if(res?.status === true) {
            setPageData(res?.data)
            console.log(res.data)
        }
	}

    const [pageData2, setPageData2] = useState("")
    async function loadData2() {
        const formData = new FormData()
        formData.append("uid13", brixtenData?.uid)
        const res = await ApiFile("post", "loaduser.php", formData)
        if(res?.status === true) {
            setPageData2(res?.data)
        }
	}

    useEffect(() => {
        loadData2()
        loadData()
    }, [])


    return (
        <Flex w="100%" direction="column">
            <Text fontSize={["13px", "15px"]} textTransform="capitalize" color="#6b7280">Welcome {brixtenData?.fname}</Text>
            <Text mt="5" fontSize={["18px", "20px"]} fontWeight="600">Trading Portfolio</Text>

            <Flex w="100%" justify="space-between" mt="8" direction={["column", "row"]}>
                <Flex mb={["4", "0"]} w={["100%", "48%"]} borderRadius=".5rem" direction="column" justify="center" boxShadow="rgba(0, 0, 0, 0.05) 0px 1px 10px 4px" border="1px solid #e8effa" py={["5", "8"]} px="6" bg="rgba(76, 167, 217, .02)">
                    <Flex w={["90px", "120px"]} h={["90px", "120px"]} borderRadius="100%" border="1px solid #6b7280" justify="center" align="center" bg="#fff"><Image src={wallet} w={["40px", "65px"]} /></Flex>
                    <Text mt="6" color="#6b7280">Account Balance</Text>
                    <Flex align="center" justify="space-between"  mt="1" w="100%">
                        <Text fontWeight="700" fontSize={["13px", "15px"]}>Total:</Text>
                        <Text fontSize={["20px", "30px"]} fontWeight="700">${parseInt(bal1)?.toLocaleString()}.00</Text>
                    </Flex>
                </Flex>




                <Flex w={["100%", "48%"]} direction="column">
                    <Flex w="100%" borderRadius=".5rem" direction="column" justify="center" bg="#edfaed" py="6" px="6">
                        <Text color="#6b7280">Trading Balance</Text>
                        <Flex align="center" justify="space-between"  mt="1" w="100%">
                            <Text fontWeight="700" fontSize={["13px", "15px"]}>Total:</Text>
                            <Text fontSize={["20px", "30px"]} fontWeight="700">${parseInt(bal3)?.toLocaleString()}.00</Text>
                        </Flex>
                    </Flex>

                    <Flex mt={["3", "2"]} w="100%" borderRadius=".5rem" direction="column" justify="center" bg="#fdede0" py="6" px="6">
                        <Flex align="center" justify="space-between" w="100%">
                            <Text fontWeight="600" fontSize={["13px", "15px"]}>Total Profit:</Text>
                            <Text fontSize={["20px", "30px"]} fontWeight="700">${parseFloat(bal2).toFixed(2)?.toLocaleString()}</Text>
                        </Flex>
                
                        <Text mt="4" color="#6b7280" fontSize="13px" mb="2">Profit Level</Text>
                        <Flex w="100%" borderRadius="8px" bg="#fff" border="2px solid #000">
                            <Flex borderRadius="8px 0 0 8px" w={brixtenData?.level+"%"} bg="#000" justify="center" fontSize="10px" py="1px" color="#fff">{brixtenData?.level}%</Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex mt={["10", "12"]} pb="3" borderBottom="1px solid #e5e7eb" justify="space-between" align="center" direction={["column-reverse", "row"]}>
                <Text fontWeight="500" fontSize="18px" w={["100%", "auto"]}>Trading Plan(s)</Text>
                <Flex mb={["12", "0"]} align="center" w={["100%", "auto"]} fontSize={["13px", "15px"]}  flexWrap="wrap">
                    <Flex mr='2' display={["none", "flex"]} cursor="pointer" color="#000" fontWeight="500" borderRadius=".5rem" bg="rgba(76, 167, 217, .02)" border="2px solid #000" _hover={{ letterSpacing: "1px" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => {
                        const element = document.getElementById('recArea')
                        return window.scrollTo({
                        top: element ? element.offsetTop - 110 : 0,
                        })
                    }}>Transactions <Text ml="2"><i className="mdi mdi-timetable"></i></Text></Flex>

                    <Flex mr="2" mb={["3", "0"]} cursor="pointer" color="#000" fontWeight="500" borderRadius=".5rem" border="2px solid #000" _hover={{ letterSpacing: "1px" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => setMenu3(true)}>Add to Trading Balance <Text ml="2"><i className="mdi mdi-currency-usd"></i></Text></Flex>

                    <Flex mr="auto" w="62%" display={["flex", "none"]} cursor="pointer" color="#000" fontWeight="500" borderRadius=".5rem" bg="rgba(76, 167, 217, .02)" border="2px solid #000" _hover={{ letterSpacing: "1px" }} transition="300ms ease-in-out" padding="12px 22px" justify="flex-start" align="center" onClick={() => {
                        const element = document.getElementById('recArea')
                        return window.scrollTo({
                        top: element ? element.offsetTop - 110 : 0,
                        })
                    }}>Transactions <Text ml="2"><i className="mdi mdi-timetable"></i></Text></Flex>
                    
                    <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#000" border="2px solid #000" _hover={{ letterSpacing: "1px" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => history.push("/dashboard/deposit")}>Deposit <Text ml="2"><i className="mdi mdi-wallet"></i></Text></Flex>
                </Flex>
            </Flex>

            <Flex w="100%" mt="4" borderRadius=".5rem" bg="#fff" boxShadow="0 0.75rem 1.5rem rgba(18,38,63,.03)" p="5"  border="1px solid #e8effa">
                <Box w="100%" overflowX={["scroll", "hidden"]} borderRadius="10px">
                    <Flex w={["650px", "100%" ]} py="5" px="5" borderRadius="10px" direction="column">
                        <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="700" borderBottom="1px solid rgb(200,200,220)">
                            <Flex w='7%'>S/N.</Flex>
                            <Flex w='13%'>Status</Flex>
                            <Flex w='20%'>Type</Flex>
                            <Flex w='20%'>Amount</Flex>
                            <Flex w='20%'>Initial Profit</Flex>
                            <Flex w='20%'>Date</Flex>
                        </Flex>
                        {
                            Array.isArray(pageData2) &&
                            pageData2?.map((item, index) => (  
                                <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} borderBottom="1px solid rgb(200,200,220)" key={index}>
                                    <Flex w='7%'>0{index +1}.</Flex>
                                    <Flex w='13%'><Text color={item.type === "CHAMBERS" ? "#02ab4c" : item.type === "PREMIUM" ? "orange" : "blue"}><i className="mdi mdi-poll"></i></Text></Flex>
                                    <Flex w='20%'>{item.type}</Flex>
                                    <Flex w='20%'>${item.amount?.toLocaleString()}</Flex>
                                    <Flex w='20%' wordBreak="break-all">${parseFloat(item.profit).toFixed(2)?.toLocaleString()}</Flex>
                                    <Flex w='20%' wordBreak="break-all">{item.date}</Flex>
                                </Flex>
                            ))
                        }
                    </Flex>
                </Box>
            </Flex>


            <Flex mt="12" pb="3" mb="5" borderBottom="1px solid #e5e7eb" justify="space-between" align="center">
                <Text fontWeight="500" fontSize="18px">Real-Time Trade Data</Text>
            </Flex>
            <Text w="100%" h={["300px", "400px"]}>
                <TradingViewWidget />
            </Text>


            <Flex mt="12" pb="3" mb="5" borderBottom="1px solid #e5e7eb" justify="space-between" align="center" id="recArea">
                <Text fontWeight="500" fontSize="18px">Your Recent Activities</Text>
            </Flex>

            <Box bg="rgba(229, 236, 252,0.5)" w="100%" overflowX={["scroll", "hidden"]} borderRadius="10px" border="1px solid #6e778a">
                <Flex w={["380px", "100%" ]} py="5" px="5" borderRadius="10px" direction="column">
                    <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="700" borderBottom="1px solid rgb(200,200,220)">
                        <Flex w='20%'>S/N.</Flex>
                        <Flex w='55%'>Action</Flex>
                        <Flex w='25%'>Date</Flex>
                    </Flex>
                    {
                        Array.isArray(pageData) &&
                        pageData?.map((item, index) => (  
                            <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} borderBottom="1px solid rgb(200,200,220)" key={index}>
                                <Flex w='20%'>0{index +1}.</Flex>
                                <Flex w='55%' wordBreak="break-all"><Text mr="5">{item.action}</Text></Flex>
                                <Flex w='25%' wordBreak="break-all">{item.date}</Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Box>

        </Flex>
    )
}