import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner, Box } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile } from "./request";
import wd from "./wd.png"



export default function D3({ bal1, setBal1, brixtenData }) {
    const history = useHistory()
    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")

    const [pageData, setPageData] = useState("")
    async function loadData() {
        const formData = new FormData()
        formData.append("uid4", brixtenData?.uid)
        const res = await ApiFile("post", "loaduser.php", formData)
        if(res?.status === true) {
            setPageData(res?.data)
        }
	}

    useEffect(() => {
        loadData()
    }, [])

    
    const [loading, setLoading] = useState(false)

    async function addData() {
        const a = ref1.current.value
        const b = ref2.current.value
        const c = ref3.current.value
        if(a !== "" && b !== "" && c !== "") {
            if(parseInt(b) > parseInt(bal1) || parseInt(b) === 0) {
                Toast("You do not have a sufficient balance to make a withdrawal", "error")
            }
            else {
                setLoading(true)
                const formData = new FormData()
                formData.append("uid6", brixtenData?.uid)
                formData.append("coin", a)
                formData.append("amount", b)
                formData.append("wallet", c)
                const res = await ApiFile("post", "loaduser.php", formData)
                if(res?.status === true) {
                    loadData()
                    sessionStorage.setItem("brixtenBal1", res.data.bal)
                    setBal1(res.data.bal)
                    Toast(res?.message, "success")
                    ref1.current.value = ""
                    ref2.current.value = ""
                    ref3.current.value = ""
                }
                setLoading(false)
            }
        }
        else {
            Toast("Kindly fill in all the fields", "error")
        }
	}

    return (
        <Flex w="100%" direction="column">
            <Text fontWeight="600" fontSize="20px">WITHDRAW ASSET</Text>
            <Text fontSize="13px" color="#6b7280">Withdraw your funds by selecting your preferred cryptocurrency as the medium of exchange. <Text>The minimum amount you can withdraw at a time is $100. Withdrawal processing occurs between 4-6 days.</Text></Text>
            <Flex w="100%" align="center" mt="5">
                <Flex mr="5" w="60px" h="60px" borderRadius="100%" border="1px solid #6b7280" justify="center" align="center"><Image w="30px" src={wd} /></Flex>
                <Flex flex="1" fontSize={["24px", "30px"]} fontWeight="700">${parseInt(bal1)?.toLocaleString()}.00</Flex>
            </Flex>

            <Flex w="100%" direction="column" mt={["8", "10"]}>
                <Flex w="100%" align="center" justify="space-between" direction={["column", "row"]}>
                    <Flex w={["100%", "30%"]} direction="column">
                        <Text fontWeight="600" fontSize="13px" mb="1">Coin Type</Text>
                        <Text w="100%"><select style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} ref={ref1}>
                            <option value="" selected disabled>Choose Coin</option>
                            <option>BTC</option>
                            <option>USDT (ERC 20)</option>
                            <option>USDT (TRC 20)</option>
                            <option>ETH</option>
                        </select></Text>
                    </Flex>

                    <Flex w={["100%", "30%"]} mt={["4", "0"]} direction="column">
                        <Text fontWeight="600" fontSize="13px" mb="1">Amount</Text>
                        <Text w="100%"><input type="number" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the amount in $" ref={ref2} /></Text>
                    </Flex>

                    <Flex w={["100%", "30%"]} mt={["4", "0"]} direction="column">
                        <Text fontWeight="600" fontSize="13px" mb="1">Wallet</Text>
                        <Text w="100%"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your wallet address" ref={ref3} /></Text>
                    </Flex>
                </Flex>

                <Flex w={["50%", "25%"]} mt="4" ml="auto" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Withdraw <Text ml="2"><i className="mdi mdi-wallet"></i></Text></>}</Flex>
            </Flex>

            <Flex mt="12" pb="3" mb="5" borderBottom="1px solid #e5e7eb" justify="space-between" align="center">
                <Text fontWeight="500" fontSize="18px">Withdrawal History</Text>
            </Flex>
            <Box bg="rgba(228, 244, 247, .5)" w="100%" overflowX={["scroll", "hidden"]} borderRadius="10px" border="1px solid #6e778a">
                <Flex w={["500px", "100%" ]} py="5" px="5" borderRadius="10px" direction="column">
                    <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="700" borderBottom="1px solid rgb(200,200,220)">
                        <Flex w='10%'>S/N.</Flex>
                        <Flex w='20%'>Coin Type</Flex>
                        <Flex w='30%'>Wallet</Flex>
                        <Flex w='20%'>Amount</Flex>
                        <Flex w='20%'>Date</Flex>
                    </Flex>
                    {
                        Array.isArray(pageData) &&
                        pageData?.map((item, index) => (  
                            <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} borderBottom="1px solid rgb(200,200,220)" key={index}>
                                <Flex w='10%'>0{index +1}.</Flex>
                                <Flex w='20%' wordBreak="break-all">{item.coin}</Flex>
                                <Flex w='30%' wordBreak="break-all"><Text mr="4" fontSize="12px">{item.wallet}</Text></Flex>
                                <Flex w='20%'>${parseInt(item.amount).toLocaleString()}</Flex>
                                <Flex w='20%'>{item.date}</Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Box>
        </Flex>
    )
}