import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import cover3 from "./cover3.jpg"
import cover4 from "./cover4.jpg"
import globalImage from "./global.svg";



export default function About() {
    const history = useHistory()
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" align="center" px={["0", "8%"]} pt={["0", "6"]} pb={["0", "60px"]}>
                <Flex w="100%" py="5" px={["3", "5"]} bgColor="#000" backgroundImage={cover3} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius={["0", "8px"]} justify="flex-end">
                    <Flex w={["100%", "70%"]} direction="column" px={["6", "10"]} bg={["rgba(0,0,0,.61)", "rgba(0,0,0,.71)"]} backdropFilter={["blur(1px)", "blur(5px)"]} mt={["12", "100px"]} py={["8", "12"]} color="#fff" borderRadius="8px" data-aos="fade-up">
                        <Text fontSize={["26px", "1.875rem"]} data-aos="fade-down" fontWeight="700">About Us</Text>
                        <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]}>BrixtenCove is an international company that is one of the pioneers of its industry in many areas of trading activities. BrixtenCove is one of the first digital asset and digital currency trading houses in the world! BrixtenCove provides its own wallet and covers the entire software development area with an own company. Working closely with regulators, BrixtenCove implemented an international concept for cryptocurrency trading and investments in blockchain technology and its advancements.</Text>
                        <Flex mt="7" fontSize={["13px", '15px']}>
                            <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => history.push("/register")}>Get Started <Text ml="2"><i className="mdi mdi-account-circle"></i></Text></Flex>

                            <Flex ml="3" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => history.push("/contact")}>Contact Us <Text ml="2"><i className="mdi mdi-contacts"></i></Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


            <Flex w="100%" px={["5", "8%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]} textAlign="center" fontWeight="600">Why choose us?</Text>
                <Flex direction="column" align="center">
                    <Text color="#ffd200" fontSize={["22px", "30px"]}><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i><i className="mdi mdi-star-circle" style={{ marginRight: "10px"}}></i></Text>
                </Flex>
                <Text color="#6b7280" mt="2" textAlign="center" fontSize={["13px", "15px"]} px={["2", "12%"]}>With years of experience in the financial industry, we have a deep understanding of the complexities of trading markets. We continually invest in technology and tools to provide traders with a competitive edge. We offer a wealth of educational resources, including tutorials, webinars, and expert analysis, to help traders improve their skills. Our dedicated support team is available 24/5 to assist clients with any questions or concerns. We are fully compliant with relevant regulations to ensure a safe and secure trading environment</Text>
            </Flex>

            <Flex px={["8", "20%"]} align="center" justify="space-between" fontSize={["30px", "70px" ]} fontWeight="700" py={["60px", "80px"]} backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right">
                <Flex align="center" direction="column">
                    <Flex w={["70px", "120px"]} h={["70px", "120px"]} borderRadius="100%" justify="center" align="center" fontSize={["35px", "60px"]} border="2px solid #fff"><i className="mdi mdi-gesture-tap"></i></Flex>
                    <Text mt="3" fontSize={["11px", "15px"]} fontWeight="400" textAlign="center" color="#cecece">Innovation</Text>
                </Flex>

                <Flex align="center" direction="column">
                    <Flex w={["70px", "120px"]} h={["70px", "120px"]} borderRadius="100%" justify="center" align="center" fontSize={["35px", "60px"]} border="2px solid #fff"><i className="mdi mdi-security"></i></Flex>
                    <Text mt="3" fontSize={["11px", "15px"]} fontWeight="400" textAlign="center" color="#cecece">Security</Text>
                </Flex>

                <Flex align="center" direction="column">
                    <Flex w={["70px", "120px"]} h={["70px", "120px"]} borderRadius="100%" justify="center" align="center" fontSize={["35px", "60px"]} border="2px solid #fff"><i className="mdi mdi-human-handsdown"></i></Flex>
                    <Text mt="3" fontSize={["11px", "15px"]} fontWeight="400" textAlign="center" color="#cecece">Transparency</Text>
                </Flex>
            </Flex>


            <Flex w="100%" px={["5", "8%"]} justify="space-between" py={["60px", "80px"]} bg="#fff" color="#000" align="center" direction={["column", "row"]}>
                <Flex mb={["8", "0"]} h={["250px", "400px"]} w={["100%", "47%"]} backgroundImage={cover4} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius=".5rem"></Flex>
                <Flex w={["100%", "47%"]} direction="column">
                    <Text fontSize={["1.5rem", "1.875rem"]} fontWeight="600">Who we are</Text>
                    <Text color="#6b7280" mt="2" textAlign="justify" fontSize={["13px", "15px"]}>BrixtenCove is a leading online trading platform dedicated to providing a seamless and secure trading experience for our valued clients. Our company is driven by a team of experienced professionals with a deep passion for financial markets and a commitment to delivering excellence in trading services. Whether you are a new trader looking to get started or an experienced trader seeking a better trading platform, we invite you to join us at BrixtenCove</Text>
                    <Flex mt="7">
                        <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => history.push("/register")}>Join Us <Text ml="2"><i className="mdi mdi-account-plus"></i></Text></Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}