import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
  	ChakraProvider,
} from "@chakra-ui/react";
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Landing from './landing';
import Login from './login';
import Register from './register';
import ScrollToTop from "./sc";
import Nav from './nav';
import Footer from "./footer"
import Dashboard from './dashboard';
import AdShow from './ad';
import About from './about';
import Risk from './risk';
import Signal from './signal';
import Tbot from './tbot';
import Contact from './contact';
import Verify from './verify';
import Forgot from './forgot';
import ForgotVerify from './fg';

function App() {
	const [isLogged, setIsLogged] = useState(sessionStorage.getItem("brixtenisLogged") !== null ? true : false)
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])

	return (
		<ChakraProvider>
			<Flex w="100%" fontSize="15px" bg="#171717" color="#fff">
				<Router>				
					<ScrollToTop>
						<Flex w="100%" direction="column">
							<Nav isLogged={isLogged} setIsLogged={setIsLogged} />
							<Switch>
								<Route exact path="/"><Landing /></Route>
								<Route path="/about"><About /></Route>
								<Route path="/management"><Risk /></Route>
								<Route path="/trade-signals"><Signal /></Route>
								<Route path="/trading-bot"><Tbot isLogged={isLogged} /></Route>
								<Route path="/contact"><Contact /></Route>
								<Route path="/forgot-password"><Forgot isLogged={isLogged} /></Route>
								<Route path="/recover-password/:slug"><ForgotVerify isLogged={isLogged} /></Route>
								<Route path="/verify-email/:slug"><Verify /></Route>
								<Route path="/register"><Register isLogged={isLogged} /></Route>
								<Route path="/login"><Login isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route path="/dashboard"><Dashboard isLogged={isLogged} setIsLogged={setIsLogged} /></Route>
								<Route><Landing /></Route>
							</Switch>
							<Footer />
							<AdShow />
						</Flex>
					</ScrollToTop>
				</Router>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
