import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import cover6 from "./cover6.jpg"
import d1 from "./d1.jpeg"
import d2 from "./d2.jpeg"
import d3 from "./d3.jpeg"
import d4 from "./d4.jpeg"
import d5 from "./d5.jpeg"
import d6 from "./d6.jpeg"
import d7 from "./d7.jpeg"
import d8 from "./d8.jpeg"
import d9 from "./d9.jpeg"
import d10 from "./d10.jpeg"



export default function Signal() {
    const history = useHistory()
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" align="center" px={["0", "8%"]} pt={["0", "6"]} pb={["0", "60px"]}>
                <Flex w="100%" py="5" px={["3", "5"]} bgColor="#000" backgroundImage={cover6} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius={["0", "8px"]} justify="flex-end">
                    <Flex w={["100%", "70%"]} direction="column" px={["6", "10"]} bg={["rgba(0,0,0,.61)", "rgba(0,0,0,.71)"]} backdropFilter={["blur(1px)", "blur(5px)"]} mt={["12", "100px"]} py={["8", "12"]} color="#fff" borderRadius="8px" data-aos="fade-up">
                        <Text fontSize={["26px", "1.875rem"]} data-aos="fade-down" fontWeight="700">Past Trade Signals</Text>
                        <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]}>Welcome to our comprehensive archive of past trade signals. At BrixtenCove, we understand the importance of transparency and learning from historical data. This section is designed to provide you with a valuable resource for reviewing and analyzing our past trade signals.</Text>
                        <Flex mt="7">
                            <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => history.push("/dashboard")}>Begin Today <Text ml="2"><i className="mdi mdi-chart-bar"></i></Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


            <Flex w="100%" px={["3", "8%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]} textAlign="center" fontWeight="600">Some of our Past Signals</Text>
                <Text color="#6b7280" mt="2" textAlign="center" px={["4", "15%"]} fontSize={["13px", "15px"]}>Our past trade signal archives are categorized by date and trading instrument. Examining past signals allows traders to understand the strategies employed and learn from both successful and unsuccessful trades</Text>
                <Flex w='100%' mt="12" flexWrap="wrap">
                    {
                        [{date: "MAY 2023", dp: d10}, {date: "APRIL 2023", dp: d1}, {date: "MARCH 2023", dp: d2}, {date: "FEBRUARY 2023", dp: d3}, {date: "JANUARY 2023", dp: d4}, {date: "DECEMBER 2022", dp: d5}, {date: "NOVEMBER 2022", dp: d6}, {date: "OCTOBER 2022", dp: d7}, {date: "SEPTEMBER 2022", dp: d8}, {date: "AUGUST 2022", dp: d9}].map((item, index) => (
                            <Flex mb="10" direction="column" key={index}>
                                <Flex align="center">
                                    <Flex w="50px" h="50px" fontSize="20px" justify="center" align="center" borderRadius="100%" border="2px solid #000" fontWeight="600">{index < 9 && "0"}{index + 1}.</Flex>
                                    <Text ml="3" fontWeight="600">{item.date}</Text>
                                </Flex>
                                <Flex mt="5" w="100%"><Image w="100%" src={item.dp} borderRadius=".5rem" /></Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}