import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner, Box } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile } from "./request";



export default function D7({ kyc, setKyc, brixtenData, setbrixtenData }) {
    const history = useHistory()
    const [selectedFile, setSelectedFile] = useState(null)
    const [selectedFile2, setSelectedFile2] = useState(null)

    const [loading, setLoading] = useState(false)

    async function addData() {
        if(selectedFile !== null && selectedFile2 !== null) {    
            setLoading(true)
            const formData = new FormData()
            formData.append("uid14", brixtenData?.uid)
            formData.append("passport", selectedFile2)
            formData.append("card", selectedFile)
            const res = await ApiFile("post", "loaduser.php", formData)
            if(res?.status === true) {
                sessionStorage.setItem("brixtenKy", res.data.kyc)
                setKyc(res.data.kyc)
                Toast(res?.message, "success")
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Select your valid ID Card and  passport", "error")
        }
    }
    

    return (
        <Flex w="100%" direction="column">
            <Text fontWeight="600" fontSize="20px">KYC VERIFICATION</Text>
            <Text fontSize="13px" color="#6b7280" pr={["0", "40%"]}>Financial regulators require us to know more about you. Upload your documents below to get verified in order to make deposits.</Text>

            
            <Flex mt={["8", "8"]} w={["100%", "70%"]} borderRadius=".5rem" boxShadow="rgba(0, 0, 0, 0.07) 0px 1px 10px 4px" py="8" px="6" align={["flex-start", "center"]}>
                <Flex mr="4" w="60px" h="60px" borderRadius="100%" border={kyc === "no" ? "1px solid red" : "1px solid #02ab4c"} justify="center" align="center" fontSize="30px" color={kyc === "no" ? "red" : "#02ab4c"}><i className="mdi mdi-account-key"></i></Flex>
                <Flex flex="1" color="#6b7280" fontSize="13px" direction="column">
                    <Text fontWeight="600" color="#000">{kyc === "no" ? "NOT VERIFIED" : "VERIFIED"} <Text ml="2" as="span" color={kyc === "no" ? "red" : "#02ab4c"}><i className={kyc === "no" ? "mdi mdi-close-circle" : "mdi mdi-checkbox-marked-circle"}></i></Text></Text>
                    <Text>{kyc === "no" ? "Kindly upload your documents in order to be verified. By doing this you will be allowed to make deposits" : "Your KYC documents has been submitted. You can now proceed to make deposits!"}</Text>
                </Flex>
            </Flex>

            <Flex direction="column" mt="6" w={["100%", "70%"]} borderRadius=".5rem" boxShadow="rgba(0, 0, 0, 0.07) 0px 1px 10px 4px" py="8" px="6">
                <Text fontWeight="600" fontSize="13px" mb="1.5">Valid identity card. (e.g. Drivers licence, international passport or any government approved document).</Text>
                <Text w="100%"><input type="file" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} onChange={(e) => {
                    setSelectedFile(e.target.files[0])
                }} /></Text>

                <Text mt="5" fontWeight="600" fontSize="13px" mb="1.5">Passport photogragh</Text>
                <Text w="100%"><input type="file" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} accept="image/x-png,image/jpeg" onChange={(e) => {
                    setSelectedFile2(e.target.files[0])
                }} /></Text>

                <Flex w="100%" mt="5" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Submit documents<Text ml="2"><i className="mdi mdi-layers"></i></Text></>}</Flex>
            </Flex>
        </Flex>
    )
}