import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ApiFile } from "./request";
import Toast from "./toast";
import logo from "./logo.png"



export default function Verify() {
    const {slug} = useParams()
    const history = useHistory()

    async function addData() {
        const formData = new FormData()
        formData.append("uid11", slug)
        const res = await ApiFile("post", "loaduser.php", formData)
        if(res?.status === true) {
            Toast(res?.message, "success")
            history.push("/login")
        }
        else {
            Toast(res?.message, "error")
            history.push("/")
        }
	}

    useEffect(() => {
        addData()
    }, [])

    return (
        <Flex w="100%" direction="column" h="100vh" justify="center" align="center">
            <Image w="100px" h="100px" src={logo} />
            <Text mt="4" mb="4" fontSize="18px">Verifying your BrixtenCove Account</Text>
            <Spinner color="#fff" emptyColor="lightgrey" />
        </Flex>
    )
}