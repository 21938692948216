import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { Accordion, AccordionItem as NewItem } from '@szhsin/react-accordion'
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import cover1 from "./cover1.gif"
import cover2 from "./cover2.jpg"
import w1 from "./w1.png"
import w2 from "./w2.png"
import w3 from "./w3.png"
import w4 from "./w4.png"
import c1 from "./c1.svg"
import c2 from "./c2.svg"
import c3 from "./c3.svg"
import c4 from "./c4.svg"
import g1 from "./g1.jpg"
import g2 from "./g2.jpg"
import g3 from "./g3.jpg"
import s1 from "./s1.svg"
import s2 from "./s2.svg"
import s3 from "./s3.svg"
import s4 from "./s4.svg"
import s5 from "./s5.svg"
import t1 from "./t1.png"
import globalImage from "./global.svg";
import man1 from "./man1.avif"
import man2 from "./man2.avif"
import woman1 from "./woman1.jpg"
import woman2 from "./woman2.jpg"
import m1 from "./m1.png"
import m2 from "./m2.png"
import m3 from "./m3.png"
import m4 from "./m4.png"



export default function Landing() {
    const history = useHistory()

    const AccordionItem = ({ header, ...rest }) => (
        <NewItem
          {...rest}
          header={
            <Flex align="center" w="100%" mb={["-2", "0"]} color="#fff">
              <Text className="font" fontSize={["16px", "18px"]} fontWeight="400">{header}</Text>
              <i className='mdi mdi-chevron-down chevron-down' style={{ fontSize: '25px' }}></i>
            </Flex>
          }
        />
    )

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" px={["5", "8%"]} justify="space-between" pt="6" pb="40px" direction={["column-reverse", "row"]}>
                <Flex w={["100%", "47%"]} direction="column" justify="center" data-aos="fade-right">
                    <Text fontSize={["2.25rem", "3.75rem"]} lineHeight={["2.5rem", "1"]} fontWeight="700" textAlign={["center", "left"]}>The world’s fastest Trading Platform.</Text>
                    <Text mt="6" color="#cecece" fontSize="16px" textAlign={["center", "left"]}>A faster, clearer and smarter way to trade. Seize your opportunities with a cutting-edge platform built around your needs.</Text>
                    
                    <Flex mt="6" justify={["center", "flex-start"]}><Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => history.push("/register")}>Get Started <Text ml="2"><i className="mdi mdi-account-circle"></i></Text></Flex></Flex>
                </Flex>

                <Flex mb={["8", "0"]} w={["100%", "45%"]} justify="center" data-aos="fade-left">
                    <Image src={cover1} w={["180px", "300px"]} h="auto" />
                </Flex>
            </Flex>





            
            <Flex w="100%" px={["5", "8%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]}  textAlign="center" fontWeight="600" data-aos="fade-down">Why trade with us?</Text>
                <Text color="#6b7280" mt="2" textAlign="center" px={["2", "15%"]} data-aos="fade-up" fontSize={["13px", "15px"]}>We provide a user-friendly interface with advanced trading tools and features, making it an ideal platform for both novice and experienced traders.</Text>

                <Flex w="100%" mt={["8", "60px"]} justify="space-between" direction={["column", "row"]}>
                    <Flex w={["100%", "45%"]}>
                        <Flex w="100%" h={["200px", "100%"]} backgroundImage={cover2} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius=".5rem"></Flex>
                    </Flex>
                    <Flex w={["auto", "46%"]} mt={["10", "0"]} direction="column" borderLeft="1px solid #6b7280" ml={["30px", "0"]}>
                        {
                            [{name: "Fast Execution", sub: "Our execution servers are located in Equinix data centres, where most of the world's financial institutions are hosted, ensuring optimal performance at all times.", dp: w1}, {name: "MT4 & MT5 Platforms", sub: "Trade effortlessly on intuitive, market-leading platforms designed and built for traders worldwide via our cutting-edge technology infrastructure.", dp: w2}, {name: "Global Market Opportunities", sub: "Expand your portfolio and discover over 1000 financial instruments across a range of CFD asset classes, including Forex, Indices, Commodities, Shares and Cryptocurrencies.", dp: w3}, {name: "Fast Payment Methods", sub: "Your money. Your choice. We offer a wide range of deposit and withdrawal methods to suit your needs.", dp: w4}].map((item, index) => (
                                <Flex key={index} w="100%" align="flex-start" mb="10" data-aos="fade-up">
                                    <Image src={item.dp} w="60px" h="60px" ml="-30px" bg="#fff" />
                                    <Flex flex="1" ml="5" direction="column">
                                        <Text mb="1" fontWeight="600">{item.name}</Text>
                                        <Text color="#6b7280" fontSize="12px">{item.sub}</Text>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>
                </Flex>
            </Flex>








            
            <Flex w="100%" px="10%" pt={["60px", "80px"]} pb={["40px", "80px"]} direction="column">
                <Text fontSize={["1.5rem", "1.875rem"]}  textAlign="center" fontWeight="600" data-aos="fade-down">Control in one place</Text>
                <Flex w="100%" mt={["10", "12"]} justify="space-between" align={["center", "flex-start"]} direction={["column", "row"]}>
                    {
                        [{name: "Exchange", sub: "Use our app for exchange between a lot of crypto coins", dp: c1}, {name: "Earn", sub: "Earn crypto with an easy to use interface", dp: c2}, {name: "Send", sub: "Send different crypto by a few clicks", dp: c3}, {name: "Store", sub: "Collect your money using our app", dp: c4}].map((item, index) => (
                            <Flex mb={["8", "0"]} key={index} w={["80%", "20%"]} direction="column" align="center" data-aos="fade-up">
                                <Flex w='120px' h="120px" borderRadius=".5rem" bg="rgba(40, 129, 119, .2)" justify="center" align="center">
                                    <Image src={item.dp} w="80px" />
                                </Flex>
                                <Text textAlign="center" mt="4" fontSize={["18px", "20px"]} fontWeight="600">{item.name}</Text>
                                <Text textAlign="center" color="#cecece" fontSize={["14px", '15px']} mt="1">{item.sub}</Text>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>






            <Flex w="100%" px={["5", "8%"]} py={["60px", "80px"]} direction="column" bg="#2a494a">
                <Text fontSize={["1.5rem", "1.875rem"]}  textAlign="center" fontWeight="600" data-aos="fade-down">Many digital assets have a high volatility</Text>
                <Text color="#cecece" mt="2" textAlign="center" px={["2", "15%"]} data-aos="fade-up" fontSize={["13px", "15px"]}>Traditional valuation and analysis strategies cannot be applied to the majority of these assets because they are based on a typical and new fundamentals.</Text>
                <Flex w="100%" mt={["10", "12"]} justify="space-between" align="center" direction={["column", "row"]}>
                    {
                        [{name: "Bitcoin Uptrends", dp: g1}, {name: "Bitcoin charts down and up trend.", dp: g2}, {name: "Bitcoin downtrends.", dp: g3}].map((item, index) => (
                            <Flex key={index} w={["100%", "30%"]} direction="column" align="center" data-aos="zoom-in" mb={["8", "0"]}>
                                <Image src={item.dp} w="100%" />
                                <Text textAlign="center" color="#fff" mt="5">{item.name}</Text>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>






            <Flex w="100%" px={["5", "8%"]} py={["60px", "80px"]} direction="column" bg="#fff" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]}  textAlign="center" fontWeight="600" data-aos="fade-down">Wide range of tokens</Text>
                <Text color="#6b7280" mt="2" textAlign="center" px={["2", "15%"]} data-aos="fade-up">Traditional valuation and analysis strategies cannot be applied to the majority of these assets because they are based on a typical and new fundamentals.</Text>
                <Flex w="100%" mt={["10", "12"]} justify="space-between" flexWrap="wrap" direction={["column", "row"]}>
                    {
                        [{name: "Equity Token", sub: "Are like shares offered Equity Token by a start-up, where individual investors receive shares, voting rights and co-ownership of the blockchain", dp: s1}, {name: "Security Token", sub: "Also behave like a security. Investors participate in the token’s profit or turnover promise and thus often have repayment obligations. Whether a token is classified as a security token depends on whether the transactions are investment contracts.", dp: s2}, {name: "Utility Token", sub: "Serves projects to optimize internal scaling and administration expenses and to create a wide range of application areas. In most cases, these tokens can bring many logistical and financial benefits to investors. It is also currently being established that they represent the most successful type of token with performance.", dp: s3}, {name: "DeFi", sub: "Decentralized Finance stands for the combination of classical financial concepts and products, as they are known from banking, with blockchain technology. DeFi is about transferring well-known principles to the world of cryptocurrencies and distributed ledger technology. To that end, Decentralized Finance draws on instruments such as loans, currency exchange, interest, bonds, and more.", dp: s4}, {name: "NFT", sub: "Non-fungible tokens (NFT) are a unit of data stored on a digital ledger, called a blockchain, that certifies a digital asset to be unique and therefore not interchangeable. NFTs can be used to represent items such as photos, videos, audio, and other types of digital files. Access to any copy of the original file, however, is not restricted to the buyer of the NFT.", dp: s5}].map((item, index) => (
                            <Flex key={index} w={["100%", "32%"]} px={["5", "8"]} py="8" direction="column" align="center" boxShadow="rgba(0, 0, 0, 0.08) 0px 1px 10px 4px" mb="5" data-aos="fade-up">
                                <Flex w='120px' h="120px" borderRadius="100%" border="1px solid #288177" justify="center" align="center">
                                    <Image src={item.dp} w="60px" />
                                </Flex>
                                <Text textAlign="center" mt="4" fontSize="18px" fontWeight="600">{item.name}</Text>
                                <Text textAlign="justify" color="#6b7280" mt="1" fontSize="13px">{item.sub}</Text>
                            </Flex>
                        ))
                    }
                    <Flex w="32%"></Flex>
                </Flex>
            </Flex>






            <Flex w="100%" px={["5", "8%"]} py={["60px", "80px"]} direction="column" bg="rgb(250,250,252)" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]}  textAlign="center" fontWeight="600" data-aos="fade-down">Copy leading traders with Covesting Copy-trading Module</Text>
                <Flex w="100%" justify="space-between" align="center" mt={["10", "60px"]} direction={["column-reverse", "row"]}>
                    <Flex w={["100%", "46%"]} direction="column" data-aos="zoom-in">
                        <Text fontSize="16px" fontWeight="500">New to trading?</Text>
                        <Text color="#9ca3af" mt="1">Choose among best performing strategies and automatically copy their trading activity to get the same returns!</Text>

                        <Text fontSize="16px" fontWeight="500" mt="8">Experienced trader?</Text>
                        <Text color="#9ca3af" mt="1">Make additional income with Covesting Copy-trading Module . Earn up to 20% of all profits earned for your followers!</Text>
                    </Flex>
                    <Flex mb={["10", "0"]} w={["100%", "48%" ]} data-aos="zoom-in">
                        <Image src={t1} w="100%" />
                    </Flex>
                </Flex>
            </Flex>






            <Flex w="100%" px={["5", "12%"]} py={["60px", "80px"]} direction="column" backgroundImage={globalImage} backgroundRepeat="no-repeat" backgroundSize={["120%", "40%"]} backgroundPosition="bottom right">
                <Text fontSize={["1.5rem", "1.875rem"]} fontWeight="700" mt="2" textAlign="center" color="#fff" data-aos="fade-down">Frequently Asked Questions</Text>
                <Text textAlign="center" px={["2", "20%"]} fontSize={["13px", '15px']} color="rgb(240,240,240)" mb="8" data-aos="fade-down" fontWeight="300">Our extensive network of carriers and partners ensures efficient investment solutions, minimizing costs while maximizing returns and speed</Text>
                <Flex direction="column" w="100%" px={["2", "10%"]}>
                    <Accordion transition transitionTimeout={1000}>
                        <AccordionItem header="What types of investments are available?">There are various types of investments, including stocks, bonds, real estate, mutual funds, exchange-traded funds (ETFs), commodities, and more
                        </AccordionItem>
                        <AccordionItem header="What is the risk associated with investments?">All investments carry some level of risk. The type and degree of risk depend on the investment vehicle and market conditions. Diversification and a long-term perspective can help manage risk
                        </AccordionItem>
                        <AccordionItem header="How can I start investing?">Getting started is easy! Simply sign up for an account on our website, complete the required verification steps, and deposit funds into your account. Once your account is funded, you can start trading.
                        </AccordionItem>
                        <AccordionItem header="Is my personal and financial information secure?">Yes, we take security seriously. We employ advanced encryption technology to protect your personal and financial data. Additionally, we adhere to strict regulatory standards to ensure the safety of your funds.
                        </AccordionItem>
                        <AccordionItem header="How do I deposit and withdraw funds from my trading account?">You can deposit funds into your account using various payment methods, including e-wallets and several crypto currencies. To withdraw funds, you can submit a withdrawal request through your account dashboard, and the funds will be processed immediately.
                        </AccordionItem>
                    </Accordion>
                </Flex>
            </Flex>





            <Flex w="100%" px={["5", "20%"]} direction="column" py={["70px", "80px"]} bg="#fff" color="#000">
                <Text textAlign="center" fontSize={["26px", "1.875rem"]} fontWeight="600" mb="12" data-aos="fade-down">Our Members Reviews</Text>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    showStatus={true}
                    showIndicators={false}
                    swipeable={true}
                    showArrows={true}
                    showThumbs={false}
                    stopOnHover={false}
                >
                    {
                        [{name: "Emilie Gregory", sub: "I've been using this investment platform for years, and it's been a game-changer for my financial goals. The user-friendly interface, educational resources, and expert guidance have made investing a breeze."},{name: "Peter Jones", sub: "I'm relatively new to investing, but this platform has made the process easy to understand. The customer support team is incredibly responsive and patient in answering my questions."},{name: "Isabelle Marks", sub: "I was hesitant about crypto trading at first, but the resources on this website convinced me otherwise. It's clear they are experts in the field."},{name: "Jeremiah Turner", sub: "During the recent market volatility, the insights and market analysis provided by this platform helped me navigate the turbulence. I feel more confident in my investment decisions."}].map((item, index) => (
                            <div>
                                <Flex key={index} w="100%" direction="column" align="center" px={["2", "15%"]}>
                                    <Flex w="120px" h="120px" backgroundImage={index === 0 ? woman1 : index === 1 ? man2 : index === 2 ? woman2 : man1} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center" borderRadius="100%"></Flex>
                                    <Text textAlign="center" color="#6b7280" fontSize={["15px", "18px"]} mt="6">{item.sub}</Text>
                                    <Text textAlign="center" mt="2" fontWeight="700" fontSize={["14px", "15px"]}> - {item.name}</Text>
                                </Flex>
                            </div>
                        ))
                    }
                </Carousel>
            </Flex>
            

            <Flex w="100%" px={["5", "10%"]} py={["60px", "80px"]} direction="column" bg="rgb(252,252,252)" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]} textAlign="center" fontWeight="600" data-aos="fade-down">What do i get access to?</Text>
                <Flex w="100%" mt={["10", "60px"]} justify="space-between" flexWrap="wrap" direction={["column", "row"]}>
                    {
                        [{name: "Market experts", sub: "Each member has many years of trading experience and is working daily in the space of digital assets. Daily trade and current market analysis are handed out by the experts.", dp: m1}, {name: "Multiple asset support", sub: "A wide range of digital assets as well as FIAT currencies can be traded and are supported by our OTC trading desk", dp: m2}, {name: "Personalized service", sub: "High-end support service at every step regarding your OTC experience. Transparent handling of the whole process and market insights for you.", dp: m3}, {name: "24/7 coverage", sub: "Our trading desk is available with a global coverage (exception of major holidays)", dp: m4}].map((item, index) => (
                            <Flex key={index} w={["100%", "40%"]} mb={["8", index < 2 ? "12" : "0"]} data-aos="fade-up">
                                <Flex w='80px' h="80px" borderRadius=".5rem" bg="rgba(40, 129, 119, .18)" justify="center" align="center">
                                    <Image src={item.dp} w="50px" />
                                </Flex>
                                <Flex ml="5" fontSize="17px" fontWeight="600" direction="column" flex="1">  
                                    {item.name}
                                    <Text color="#6b7280" fontWeight="400" mt="1" fontSize="13px">{item.sub}</Text>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>

        </Flex>
    )
}