import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import logo from "./logo.png"



export default function Nav({ isLogged }) {
    const history = useHistory()
    const location = useLocation()
    const [menu, setMenu] = useState(false)

    return (
        <>
        {
            location.pathname.includes("dashboard") || location.pathname.includes("verify")  ?
            <></>
            :
            <>
            {!menu && <Flex display={["flex", "none"]} h=".4rem"></Flex>}
            <Flex w="100%" bg="rgba(23, 23, 23, 1)" backdropFilter="blur(8px)" px={["5", "8%"]} pb={["1.2rem", "1.5rem"]} pt={[".8rem", "1.5rem"]} align="center" position="sticky" top="0" boxShadow="(0 0 transparent,0 0 #0000),(0 0 transparent,0 0 #0000), 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px -1px rgba(0,0,0,.1)" zIndex="100" justify="space-between">
                <Flex align="center" mr="2" cursor="pointer" onClick={() => history.push("/")}>
                    <Image src={logo} mr="2" h="20px" />
                    <Text fontWeight={["500", "600"]} fontSize="16px">BrixtenCove</Text>
                </Flex>

                <Flex flex="1" align="center" justify="flex-end" fontSize="14px" display={["none", "flex"]}>
                    {
                        ["About Us", "The Management", "Past Trade Signals", "Auto Trading Bot", "Contact Us", "Login"].map((item, index) => (
                            <Text display={isLogged && index > 4 && "none"} color="#cecece" fontWeight="500" cursor="pointer" mr="6" key={index} _hover={{ color: "#288177", letterSpacing: "3px" }} transition="300ms ease-in-out" onClick={() => history.push(index === 0 ? "/about" : index === 1 ? "/management" : index === 2 ? "/trade-signals" : index === 3 ? "/trading-bot" : index === 4 ? "/contact" : "/login")}>{item}</Text>
                        ))
                    }

                    <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 18px" justify="center" align="center" onClick={() => history.push(isLogged ? "/dashboard" : "/register")}>{isLogged ? "Dashboard" : "Get Started"} <Text ml="2"><i className="mdi mdi-account-circle"></i></Text></Flex>
                </Flex>

                <Flex display={["flex", "none"]} cursor="pointer" w="30px" h="30px" justify="center" align="center" borderRadius="100%" fontSize="15px" color="#fff" bg="#317c7f" onClick={() => setMenu(true)}><i className="mdi mdi-menu"></i></Flex>
                {
                    menu &&
                    <Flex zIndex="1000" w="100%" position="fixed" top="0" left="0" h="100vh" align="center" direction="column" bg="#317c7f" pb={["1.2rem", "1.5rem"]} pt={[".8rem", "1.5rem"]} display={["flex", 'none']} px="5" data-aos="zoom-in" data-aos-duration="300">
                         <Flex w="100%" justify="flex-end"><Flex display={["flex", "none"]} cursor="pointer" w="30px" h="30px" justify="center" align="center" borderRadius="100%" fontSize="15px" color="#fff" bg="#317c7f" border="1px solid #fff" onClick={() => setMenu(false)}><i className="mdi mdi-close"></i></Flex></Flex>

                         <Flex flex="1" align="center" direction="column" mt="70px">
                            {
                                [isLogged ? "Dashboard" : "Home", "About Us", "The Management", "Past Trade Signals", "Auto Trading Bot", "Contact Us", "Login", "Get Started"].map((item, index) => (
                                    <Text textTransform="uppercase" mb="8" fontSize="16px" display={isLogged && index > 5 && "none"} color="#cecece" cursor="pointer" key={index} _hover={{ color: "#fff", letterSpacing: "3px" }} transition="300ms ease-in-out" onClick={() => {
                                        history.push((index === 0 && !isLogged) ? "/" : (index === 0 && isLogged) ? "/dashboard/" : index === 1 ? "/about" : index === 2 ? "/management" : index === 3 ? "/trade-signals" : index === 4 ? "/trading-bot" : index === 5 ? "/contact" : index === 6 ? "/login" : "/register")
                                        setMenu(false)
                                    }}>{item}</Text>
                                ))
                            }
                        </Flex>
                    </Flex>
                }
            </Flex>
            </>
        }
        </>
    )
}