import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner, Box } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile, ApiRequest } from "./request";
import wallet2 from "./wallet2.png"
import wallet from "./wallet.png"
import btc from "./btc.png"
import usdt from "./usdt.png"
import eth from "./eth.png"



export default function D2({ kyc, brixtenData }) {
    const history = useHistory()
    const [deposit, setDeposit] = useState(false)

    const ref1 = useRef("")
    const ref2 = useRef("")

    const [send1, setSend1] = useState("")
    const [send2, setSend2] = useState("")

    function iniDeposit() {
        if(kyc === "yes") {
            var a = ref1.current.value
            var b = ref2.current.value
            if(a !== "" && b !== "") {
                if(parseInt(b) < parseInt(100)) {
                    Toast("You cannot deposit less than $100", "error")
                }
                else {
                    setSend1(a)
                    setSend2(b)
                    setDeposit(true)
                }
            }
            else {
                Toast("Kindly choose the coin type and enter an amount", "error")
            }
        }
        else {
            Toast("Your KYC needs to be verified before you can make a deposit!", "error")
            history.push("/dashboard/kyc")
        }
    }

    const [pageData, setPageData] = useState("")
    const [loading, setLoading] = useState(false)

    async function loadData() {
        const formData = new FormData()
        formData.append("uid2", brixtenData?.uid)
        const res = await ApiFile("post", "loaduser.php", formData)
        if(res?.status === true) {
            setPageData(res?.data)
        }
	}

    async function addData() {
        setLoading(true)
        const formData = new FormData()
        formData.append("uid3", brixtenData?.uid)
        formData.append("coin", send1)
        formData.append("amount", send2)
        const res = await ApiFile("post", "loaduser.php", formData)
        if(res?.status === true) {
            await ApiRequest("post", "maildeposit.php", {
                email: brixtenData?.email,
                fname: brixtenData?.fname,
                coin: send1,
                amount: send2,
            })
            loadData()
            setDeposit(false)
            Toast(res?.message, "success")
            setTimeout(function() {
                window.tidioChatApi.open()
            }, 1500)
        }
        setLoading(false)
	}

    useEffect(() => {
        loadData()
    }, [])

    return (
        <Flex w="100%" direction="column">
            <Text fontWeight="600" fontSize="20px">DEPOSIT ASSET</Text>
            <Text fontSize="13px" color="#6b7280">Choose your preferred deposit method and enter amount</Text>

            {
                !deposit ?
                <>
                    <Flex w="100%" align={["flex-start", "center" ]}mt="5">
                        <Flex mr="5" w="70px" h="70px" borderRadius="100%" border="1px solid #6b7280" justify="center" align="center"><Image w="30px" src={wallet2} /></Flex>
                        <Flex flex="1" pr={["0", "30%"]} color="#6b7280" fontSize={["13px", "14px"]}>Note: Your KYC needs to be verified before you can make deposits. The minimum amount you can deposit to activate your trading portfolio is $100. A fee of 10% will be deducted after a successful trade.</Flex>
                    </Flex>

                    <Flex w="100%" direction="column" mt={["8", "12"]}>
                        <Flex w="100%" align="center" direction={["column", "row"]} justify="space-between">
                            <Flex w={["100%", "47%"]} direction="column">
                                <Text fontWeight="600" fontSize="13px" mb="1">Coin Type</Text>
                                <Text w="100%"><select style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} ref={ref1}>
                                    <option value="" selected disabled>Choose Coin</option>
                                    <option>BTC</option>
                                    <option>USDT (ERC 20)</option>
                                    <option>USDT (TRC 20)</option>
                                    <option>ETH</option>
                                </select></Text>
                            </Flex>

                            <Flex mt={["4", "0"]} w={["100%", "47%"]} direction="column">
                                <Text fontWeight="600" fontSize="13px" mb="1">Amount</Text>
                                <Text w="100%"><input type="number" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the amount in $" ref={ref2} /></Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex w={["50%", "40%"]} mt="4" ml="auto" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => iniDeposit()}>Continue <Text ml="2"><i className="mdi mdi-wallet"></i></Text></Flex>
                </>
                :
                <>
                    <Flex w="100%" align="center" mt={["8", "10"]}>
                        <Flex mr="5" w="60px" h="60px" borderRadius="100%" border="1px solid #6b7280" justify="center" align="center"><Image w="30px" src={wallet} /></Flex>
                        <Flex flex="1" fontSize="30px" fontWeight="700">${parseInt(send2)?.toLocaleString()}</Flex>
                    </Flex>
                    <Text fontWeight="600" fontSize="16px" mt="7">{send1} ADDRESS</Text>
                    <Flex align="center" mt="2" direction={["column", "row"]}>
                        <Text textDecoration="underline" wordBreak="break-all">{send1 === "BTC" ? "bc1q4a47kyp52u4e6kd8uw8qg65zstehpqy9ulenze" : send1 === "USDT (ERC 20)" ? "0x436EdADdcA963FDa03F6435a15F5B4d979cB6098" : send1 === "USDT (TRC 20)" ? "TMgxiRr7cDfXzr2UsTSh1DZdEVMUyqsRS1" : "0x436EdADdcA963FDa03F6435a15F5B4d979cB6098"}</Text>

                        <Flex ml={["auto", "5"]} cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="8px 15px" justify="center" align="center" fontSize="13px" onClick={() => {
                            navigator.clipboard.writeText(send1 === "BTC" ? "bc1q4a47kyp52u4e6kd8uw8qg65zstehpqy9ulenze" : send1 === "USDT (ERC 20)" ? "0x436EdADdcA963FDa03F6435a15F5B4d979cB6098" : send1 === "USDT (TRC 20)" ? "TMgxiRr7cDfXzr2UsTSh1DZdEVMUyqsRS1" : "0x436EdADdcA963FDa03F6435a15F5B4d979cB6098")
                            Toast("Wallet address copied to clipboard!", "success")
                        }}>Copy Address <Text ml="2"><i className="mdi mdi-content-copy"></i></Text></Flex>
                    </Flex>

                    <Flex w="100%" direction="column" mt="5">
                        {
                            ["Send Exactly $"+parseInt(send2)?.toLocaleString()+" "+send1+" to this address", "Sending any other coin may result in permanent loss.", "Account will be credited after verification.", "Ensure to click on the verify payment button below after sending the coin.", "Proceed to provide your transaction/payment details to the live support for verifications"].map((item, index) => (
                                <Flex align="center" key={index} mb="1" color="#6b7280" fontSize="14px">
                                    <Text mr="2" color="#288177" fontSize="20px"><i className="mdi mdi-check-all"></i></Text>
                                    {item}
                                </Flex>
                            ))
                        }
                    </Flex>
                    <Flex mb={["4", "0"]} w='100%' mt="5" align="center">
                        <Image h="40px" mr="3" src={send1 === "BTC" ? btc : send1 === "ETH" ? eth : usdt} />
                        <Flex flex="1" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Verify Payment <Text ml="2"><i className="mdi mdi-bank"></i></Text></>}</Flex>
                    </Flex>
                </>
            }




            <Flex mt={["8", "12"]} pb="3" mb="5" borderBottom="1px solid #e5e7eb" justify="space-between" align="center">
                <Text fontWeight="500" fontSize="18px">Deposit History</Text>
            </Flex>
            <Box bg="rgba(0, 185, 81, .08)" w="100%" overflowX={["scroll", "hidden"]} borderRadius="10px" border="1px solid rgb(0, 185, 81)">
                <Flex w={["600px", "100%" ]} py="5" px="5" borderRadius="10px" direction="column">
                    <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} fontWeight="700" borderBottom="1px solid rgb(200,200,220)">
                        <Flex w='10%'>S/N.</Flex>
                        <Flex w='20%'>Coin Type</Flex>
                        <Flex w='20%'>Amount</Flex>
                        <Flex w='20%'>Date</Flex>
                        <Flex w='30%'>Ref</Flex>
                    </Flex>
                    {
                        Array.isArray(pageData) &&
                        pageData?.map((item, index) => (  
                            <Flex w='100%' pb="5" mb="5" fontSize={["13px", "15px"]} borderBottom="1px solid rgb(200,200,220)" key={index}>
                                <Flex w='10%'>0{index +1}.</Flex>
                                <Flex w='20%'>{item.coin}</Flex>
                                <Flex w='20%'>${parseInt(item.amount).toLocaleString()}</Flex>
                                <Flex w='20%' wordBreak="break-all">{item.date}</Flex>
                                <Flex w='30%' wordBreak="break-all"><Text textDecoration="underline" cursor="pointer" onClick={() => {
                                    navigator.clipboard.writeText(item.ref)
                                    Toast("Reference ID copied successfully", "success")
                                }}>{item.ref}</Text></Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Box>
        </Flex>
    )
}