import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner, Box } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile } from "./request";



export default function D5({ setMenu, brixtenData, setbrixtenData }) {
    const history = useHistory()
    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")
    const ref4 = useRef("")
    const ref5 = useRef("")

    useEffect(() => {
        ref1.current.value = brixtenData?.fname
        ref2.current.value = brixtenData?.lname
        ref3.current.value = brixtenData?.uname
        ref4.current.value = brixtenData?.email
        ref5.current.value = brixtenData?.phone
    }, [])

    const [loading, setLoading] = useState(false)

    async function addData() {
        const a = ref1.current.value
        const b = ref2.current.value
        const c = ref3.current.value
        const d = ref4.current.value
        const e = ref5.current.value
        if(a !== "" && b !== "" && c !== "" && d !== "" && e !== "") {
            setLoading(true)
            const formData = new FormData()
            formData.append("uid8", brixtenData?.uid)
            formData.append("fname", a)
            formData.append("lname", b)
            formData.append("uname", c)
            formData.append("email", d)
            formData.append("phone", e)
            const res = await ApiFile("post", "loaduser.php", formData)
            if(res?.status === true) {
                Toast(res?.message, "success")
                sessionStorage.setItem("brixtenBasic", JSON.stringify(res.data))
                setbrixtenData(res.data)
            }
            else {
                Toast(res.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Kindly fill in all the fields", "error")
        }
	}


    return (
        <Flex w="100%" direction="column">
            <Text fontWeight="600" fontSize="20px">MY PROFILE</Text>
            <Text fontSize="13px" color="#6b7280">Edit & Manage your personal information</Text>
            
            <Flex mt="8" direction="column" pr={["0", "30%"]}>
                <Text fontWeight="600" fontSize="13px" mb="1">First Name</Text>
                <Text w="100%"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the First Name" ref={ref1} /></Text>
                <Text fontWeight="600" fontSize="13px" mb="1" mt="4">Last Name</Text>
                <Text w="100%"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the Last Name" ref={ref2} /></Text>
                <Text fontWeight="600" fontSize="13px" mb="1" mt="4">Username </Text>
                <Text w="100%"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the Username" ref={ref3} /></Text>
                <Text fontWeight="600" fontSize="13px" mb="1" mt="4">Email Address</Text>
                <Text w="100%"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the Email Address" ref={ref4} /></Text>
                <Text fontWeight="600" fontSize="13px" mb="1" mt="4">Phone Number</Text>
                <Text w="100%"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your the Phone Number" ref={ref5} /></Text>

                <Flex mt="5" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Update Information <Text ml="2"><i className="mdi mdi-account-edit"></i></Text></>}</Flex>

                <Flex border="1px solid #288177" mt="3" cursor="pointer" color="#000" fontWeight="500" borderRadius=".5rem" bg="#edfaed" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => setMenu(true)}>Change Password <Text ml="2"><i className="mdi mdi-lock"></i></Text></Flex>
            </Flex>
        </Flex>
    )
}