import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import cover9 from "./cover9.jpg"
import map from "./map.png"



export default function Contact() {
    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" align="center" px={["0", "8%"]} pt={["0", "6"]} pb={["0", "60px"]}>
                <Flex w="100%" py="5" px={["3", "5"]} bgColor="#000" backgroundImage={cover9} backgroundSize="cover" backgroundRepeat="no-repeat" backgroundPosition="center center" borderRadius={["0", "8px"]} justify="flex-end">
                    <Flex w={["100%", "70%"]} direction="column" px={["6", "10"]} bg={["rgba(0,0,0,.61)", "rgba(0,0,0,.71)"]} backdropFilter={["blur(1px)", "blur(5px)"]} mt={["12", "100px"]} py={["8", "12"]} color="#fff" borderRadius="8px" data-aos="fade-up">
                        <Text fontSize={["26px", "1.875rem"]} data-aos="fade-down" fontWeight="700">Send Us A Message</Text>
                        <Text mt="3" data-aos="fade-up" fontSize={["13px", "14px"]}>Welcome to BrixtenCove, your trusted partner in the world of online trading. We value your feedback, questions, and inquiries. Our dedicated team is here to assist you with any assistance you may require. Please feel free to reach out to us.</Text>
                        <Flex mt="7">
                            <Flex cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => {
                                Toast("Connecting you to the live support..", "success")
                                setTimeout(function() {
                                    window.tidioChatApi.open()
                                }, 1500)
                            }}>Get Instant Support <Text ml="2"><i className="mdi mdi-account-circle"></i></Text></Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>


            <Flex w="100%" px={["5", "8%"]} direction="column" py={["60px", "80px"]} bg="#fff" color="#000">
                <Text fontSize={["1.5rem", "1.875rem"]} textAlign="center" fontWeight="600" data-aos="fade-down">Get In Touch</Text>
                <Text color="#6b7280" mt="2" textAlign="center" fontSize={["13px", "15px"]} px={["2", "12%"]} data-aos="fade-up">For Further Enquires, Please kindly reach us by sending us a message</Text>

                <Flex mt="12" justify="space-between" w="100%" align="center" direction={["column", "row"]}>
                    <Flex w={["100%", "48%"]} direction="column">
                        <Text fontSize="1.4rem" fontWeight="600">Contact Form</Text>
                        <Text w="100%" mt="3"><input type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your fullname" ref={ref1} /></Text>
                        <Text w="100%" mt="4"><input type="email" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your email address" ref={ref2} /></Text>
                        <Text w="100%" mt="4"><textarea type="text" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px", resize: "none", height: "150px"}} placeholder="Message:" ref={ref3}></textarea></Text>

                        <Flex mt="5" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => {
                            var a = ref1.current.value
                            var b = ref2.current.value
                            var c = ref3.current.value
                            if(a !== "" && b !== "" && c !== "") {
                                setLoading(true)
                                setTimeout(function() {
                                    Toast("Your message has been recieved!! We will reply you via email shortly", "success")
                                    ref1.current.value = ""
                                    ref2.current.value = ""
                                    ref3.current.value = ""
                                    setLoading(false)
                                }, 1500)
                            }
                            else {
                                Toast("Kindly enter all fields", "error")
                            }
                        }}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Send Message <Text ml="2"><i className="mdi mdi-email"></i></Text></>}</Flex>
                    </Flex>

                    <Flex w={["100%", "48%"]} mt={["8", "0"]}>
                        <Image src={map} w="100%" borderRadius=".5rem" cursor="grab" onClick={() => history.push("/about")} />
                    </Flex>
                </Flex>
            </Flex>


        </Flex>
    )
}