import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";



export default function DashMob({ setMenu, selected, setSelected }) {
    const history = useHistory()

    return (
        <Flex zIndex="100" align="center" w="100%" bg="#fff" borderTop="1px solid #e5e7eb" px="3.5" py="3" position="fixed" bottom="0" left="0" justify="space-between">
            {
                ["Home", "Trading", "Deposit", "Withdrawal", "Transfer", "KYC", "Profile"].map((item, index) => (
                    <Flex direction="column" key={index} borderRadius=".5rem" fontWeight={index === selected ? "600" : "400"} color={index === selected ? "#000" : "#6b7280"} transition="200ms ease-in-out" cursor="pointer" align="center" onClick={() => {
                        setSelected(index)
                        history.push(index === 0 ? "/dashboard/" : index === 1 ? "/dashboard/trading" : index === 2 ? "/dashboard/deposit" : index === 3 ? "/dashboard/withdrawal" : index === 4 ? "/dashboard/transfer" : index === 5 ? "/dashboard/kyc" : index === 6 && "/dashboard/profile")
                    }}>
                        <Text fontSize="20px"><i className={index === 0 ? "mdi mdi-view-dashboard" : index === 1 ? "mdi mdi-lightbulb-on-outline" : index === 2 ? "mdi mdi-wallet" : index === 3 ? "mdi mdi-currency-usd" : index === 4 ? "mdi mdi-share" : index === 5 ? "mdi mdi-layers" : "mdi mdi-account-plus"}></i></Text>
                        <Text fontSize="8px">{item}</Text>
                    </Flex>
                ))
            }
        </Flex>
    )
}