import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import logo from "./logo.png"
import Toast from "./toast";



export default function Footer() {
    const history = useHistory()
    const location = useLocation()
    const [loading, setLoading] = useState(false)
    const ref1 = useRef("")

    return (
        <>
        {
            location.pathname.includes("login") || location.pathname.includes("register") || location.pathname.includes("dashboard") || location.pathname.includes("forgot-password") || location.pathname.includes("recover-password") || location.pathname.includes("verify") ?
            <></>
            :
            <Flex w='100%' justify="space-between" pt={["50px", "60px"]} pb={["30px", "60px"]} px={["5", "8%"]} direction={["column", "row"]}>
                <Flex w={["100%", "28%"]}>
                    <Flex w="60px" h="60px" backgroundImage={logo} backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="center center"></Flex>
                    <Text ml="6">
                        <Text fontWeight="600" fontSize="18px" mb="4">Quick Links</Text>
                        {["About Us", "Contact Us", "The Management"].map((item, index) => (
                            <Text color="#cecece" _hover={{ letterSpacing: '2px', color: "#288177" }} transition="300ms ease-in-out" fontSize="13px" mb="3" cursor="pointer" key={index} onClick={() => history.push(index === 0 ? "/about" : index === 1 ? "/contact" : "/management")}>{item}</Text>
                        ))}
                    </Text> 
                </Flex>

                <Flex w={["100%", "32%"]} mt={["8", "0"]} direction="column" display={["none", "flex"]}>
                    <Text fontWeight="600" fontSize="18px" mb={["1", "2"]}>Contact</Text>
                    <Flex align="center" color="#288177">
                        <Text fontSize="20px"><i className="mdi mdi-email"></i></Text>
                        <Text ml="2" cursor="pointer" onClick={() => window.open("mailto: info@brixtencove.com")} _hover={{ textDecoration: "underline" }}>info@brixtencove.com</Text>
                    </Flex>
                    <Text mt="3" color="#cecece" display={["none", "block"]}>&copy; 2023 BrixtenCove. All Rights Reserved</Text>
                </Flex>

                <Flex w={["100%", "30%"]} mt={["8", "0"]} direction="column">
                    <Text fontWeight="600" fontSize={["15px", "18px"]} mb="2">Newsletter</Text>
                    <Flex w='100%'><input type="email" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "10px 20px"}} placeholder="Enter your email" ref={ref1} /></Flex>

                    <Flex mt="2" w="100%" justify="center" cursor="pointer" borderRadius=".5rem" padding="10px 28px" bg="#288177" color="#fff" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" fontWeight="500" align="center" onClick={() => {
                        if(ref1.current.value !== "") {
                            setLoading(true)
                            setTimeout(function() {
                                Toast("You have been added to our newsletter", "success")
                                setLoading(false)
                                ref1.current.value = ""
                            }, 1500)
                        }
                        else {
                            Toast("Kindly enter your email", "error")
                        }
                    }}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <><Text mr="2"><i className="mdi mdi-arrow-right"></i></Text>Subscribe</>}</Flex>
                </Flex>

                <Flex w={["100%", "32%"]} mt={["8", "0"]} direction="column" display={["flex", "none"]}>
                    <Text fontWeight="600" fontSize="18px" mb={["1", "2"]}>Contact</Text>
                    <Flex align="center" color="#288177">
                        <Text fontSize="20px"><i className="mdi mdi-email"></i></Text>
                        <Text ml="2" cursor="pointer" onClick={() => window.open("mailto: info@brixtencove.com")} _hover={{ textDecoration: "underline" }}>info@brixtencove.com</Text>
                    </Flex>
                    <Text mt="3" color="#cecece" fontSize="13px">&copy; 2023 BrixtenCove. All Rights Reserved</Text>
                </Flex>
            </Flex>
        }
        </>
    )
}