import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import Toast from "./toast";
import logo from "./logo.png"
import user from "./fg.png"
import { ApiFile } from "./request";



export default function ForgotVerify({ isLogged, setIsLogged }) {
    const {slug} = useParams()
    const history = useHistory()
    useEffect(() => {
        isLogged && history.push("/dashboard")
    }, [])
    
    const ref1 = useRef("")
    const ref2 = useRef("")
    const [loading, setLoading] = useState(false)


    async function addData() {
        var a = ref1.current.value
        var b = ref2.current.value
        if(a !== "" && b !== "" && a === b) {
            setLoading(true)
            const formData = new FormData()
            formData.append("uid16", slug)
            formData.append("pass", a)
            const res = await ApiFile("post", "loaduser.php", formData)
            if(res?.status === true) {
                Toast(res?.message, "success")
                history.push("/login")
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else if(a !== b) {
            Toast("Passwords don't match kindly verify", "error")
        }
        else {
            Toast("Kindly enter your email", "error")
        }
	}

    return (
        <Flex w="100%" direction="column" align="center" py="8">
            <Image src={logo} display={["none", "block"]} w="50px" data-aos="fade-down" />

            <Flex mt="7" w={["95%", "600px"]} borderRadius=".5rem" pt="10" pb="12" px={["4", "6"]} bg="rgba(40, 129, 119, .2)" direction="column" data-aos="fade-up">
                <Flex align="center">
                    <Image src={user} h="70px" />
                    <Text ml="3">
                        <Text fontSize="24px" fontWeight="600">Recover Password</Text>
                        <Text color="#cecece" fontSize="13px">Kindly enter the a new password</Text>
                    </Text>
                </Flex>

                <Flex w="100%" mt="8" direction="column">
                    <Text color="#cecece" fontSize="13px" mb="1">New pasword</Text>
                    <Text w="100%"><input type="password" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your email" ref={ref1} /></Text>

                    <Text mt="5" color="#cecece" fontSize="13px" mb="1">Confirm pasword</Text>
                    <Text w="100%"><input type="password" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your email" ref={ref2} /></Text>

                    <Flex mt="5" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>
                        {loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Update Password <Text ml="2"><i className="mdi mdi-lock-open-outline"></i></Text></>}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}