import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner, Box, Select } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile } from "./request";



export default function D6({ bal1, bal3, setMenu3, brixtenData, setBal3, setBal2, setbrixtenData }) {
    const history = useHistory()
    const [pType, setPType] = useState("CHAMBERS")
    const [amt, setAmt] = useState(0)
    const ref1 = useRef("")
    const [profit, setProfit] = useState(0)

    useEffect(() => {
        const nc = parseInt(amt) * parseFloat(0.001)
        const np = parseInt(amt) * parseFloat(0.0005)
        const ns = parseInt(amt) * parseFloat(0.0001)
        setProfit(pType === "CHAMBERS" ? nc : pType === "PREMIUM" ? np : ns)
    }, [amt, pType])

    function det (t1, t2, b1, b2) {
        return <Flex w="100%" mb="7" fontSize="13px" justify="space-between">
            <Flex w="45%" direction="column">
                <Text color="#6b7280">{t1}</Text>
                <Text color="#02ab4c" fontWeight="500" mt="2">{b1}</Text>
            </Flex>
            <Flex w="45%" direction="column">
                <Text color="#6b7280">{t2}</Text>
                <Text color="#02ab4c" fontWeight="500" mt="2">{b2}</Text>
            </Flex>
        </Flex>
    }
    

    
    const [loading, setLoading] = useState(false)

    async function addData() {
        if(amt !== 0) {
            if(parseInt(bal3) === 0 || parseInt(bal3) < parseInt(amt)) {
                Toast("Insufficient Trading balance!", "error")
            }
            else {
                if(pType === "CHAMBERS" && parseInt(amt) < 2000) {
                    Toast("Minimum deposit for CHAMBERS plan is $2,000", "error")
                }
                else if(pType === "PREMIUM" && parseInt(amt) < 1000) {
                    Toast("Minimum deposit for PREMIUM plan is $1,000", "error")
                }
                else if(pType === "STARTER" && parseInt(amt) < 500) {
                    Toast("Minimum deposit for STARTER plan is $500", "error")
                }
                else {
                    setLoading(true)
                    const formData = new FormData()
                    formData.append("uid12", brixtenData?.uid)
                    formData.append("type", pType)
                    formData.append("amount", amt)
                    formData.append("profit", profit.toFixed(2))
                    const res = await ApiFile("post", "loaduser.php", formData)
                    if(res?.status === true) {
                        sessionStorage.setItem("brixtenBal2", res.data.trading)
                        sessionStorage.setItem("brixtenBal3", res.data.profit)
                        setBal3(res.data.trading)
                        setBal2(res.data.profit)
                        Toast(res?.message, "success")
                        ref1.current.value = ""
                        setAmt(0)
                        setProfit(0)
                        history.push("/dashboard/")
                    }
                    setLoading(false)
                }
            }
        }
        else {
            Toast("Please enter the amount you will like to trade with", "error")
        }
    }

    return (
        <Flex w="100%" direction="column" mb={["0", "60px"]}>
            <Text fontWeight="600" fontSize="20px">TRADING</Text>
            <Text fontSize="13px" color="#6b7280">Get started with your investment</Text>

            <Flex w="100%" mt={["6", "10"]} justify="space-between" direction={["column", "row"]}>
                <Flex direction="column" w={["100%", "58%"]} borderRadius=".5rem" boxShadow="rgba(0, 0, 0, 0.05) 0px 1px 10px 4px" py="8" px={["4", "6"]}>
                    <Text fontSize="13px" mb="2" color="#6b7280">Choose Plan</Text>
                    <Select size="lg" boxShadow="0 0 5px rgba(0, 0, 0, 0.1)" outline="none" _focus={{ boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)" }} onChange={(e) => setPType(e.target.value)}>
                        <option>CHAMBERS</option>
                        <option>PREMIUM</option>
                        <option>STARTER</option>
                    </Select>

                    <Text color="#6b7280" mt="10">Choose Quick Amount to Invest</Text>
                    <Flex mt="5" flexWrap="wrap" w="100%">
                        {
                            [100, 250, 500, 1000, 1500, 2000].map((item, index) => (
                                <Flex mb="2" key={index} borderRadius=".5rem" mr="3" px={["4", "7"]} py={["2", "3"]} bg="rgba(40, 129, 119, .14)" cursor="pointer" fontSize={["13px", '15px']} _hover={{ bg: "rgba(40, 129, 119, .3)" }} transition="300ms ease-in-out" fontWeight="600" onClick={() => {
                                    setAmt(item)
                                    ref1.current.value = item
                                }}>${item.toLocaleString()}</Flex>
                            ))
                        }
                    </Flex>
                    <Text mt="8" fontSize="13px" mb="2" color="#6b7280">Or Enter Your Amount</Text>
                    <Text w="100%"><input type="number" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} onInput={(e) => setAmt(e.target.value === "" ? 0 : e.target.value)} ref={ref1} /></Text>

                    
                    <Text color="#6b7280" mt="8" mb="3" fontSize="13px">Payment Method</Text>
                    <Flex boxShadow="0 0 10px rgba(0, 0, 0, 0.1)" borderRadius=".5rem" padding="10px">
                        <Flex w="100%" border="1px solid #288177" px="4" py="2" bg="rgba(40, 129, 119, .14)" borderRadius=".5rem" align="center">
                            <Text fontSize="25px"><i className="mdi mdi-wallet"></i></Text>
                            <Text ml="2" fontSize={["12px", "14px"]}>Trading Balance ${parseInt(bal3)?.toLocaleString()}</Text>
                            <Text fontSize="20px" ml='auto' color="#02ab4c"><i className="mdi mdi-check-circle"></i></Text>
                        </Flex>
                    </Flex>
                    <Text textAlign="center" color="#6b7280" fontSize="14px" mt="7">
                        Insufficient trading balance? <Text textDecoration="underline" as="span" cursor="pointer" onClick={() => setMenu3(true)} fontWeight="600" ml="2" color="#02ab4c">Add from account balance</Text>
                    </Text>
                </Flex>










                <Flex direction="column" mt={["8", "0"]} w={["100%", "38%"]} borderRadius=".5rem" boxShadow="rgba(0, 0, 0, 0.05) 0px 1px 10px 4px" py="8" px={["5", "6"]}>
                    <Text color="#6b7280" mb="8">Your Investment Details</Text>
                    {det("Name of plan", "Plan Price", pType, pType === "CHAMBERS" ? "$75,000" : pType === "PREMIUM" ? "$25,000" : "$10,000")}

                    {det("Duration", "Profit", pType === "CHAMBERS" ? "40 Days" : pType === "PREMIUM" ? "30 Days" : "20 Days", pType === "CHAMBERS" ? "0.1% Daily" : pType === "PREMIUM" ? "0.05% Daily" : "0.01% Daily")}

                    {det("Minimum Deposit", "Maximum Deposit", pType === "CHAMBERS" ? "$2,000" : pType === "PREMIUM" ? "$1,000" : "$500", pType === "CHAMBERS" ? "$75,000" : pType === "PREMIUM" ? "$25,000" : "$10,000")}

                    {det("Minimum Return", "Maximum Return", pType === "CHAMBERS" ? "40%" : pType === "PREMIUM" ? "20%" : "10%", pType === "CHAMBERS" ? "100%" : pType === "PREMIUM" ? "80%" : "60%")}
                    <Text borderBottom="1px solid #e5e7eb" mb="6" mt="2"></Text>
                    <Flex w='100%' justify="space-between">
                        <Text color="#6b7280">Payment method:</Text>
                        <Text color="#02ab4c">My Account</Text>
                    </Flex>
                    <Text borderBottom="1px solid #e5e7eb" mb="6" mt="6"></Text>
                    <Flex w='100%' justify="space-between">
                        <Text color="#6b7280">Amount to Invest:</Text>
                        <Text color="#02ab4c" fontWeight="600">${parseInt(amt)?.toLocaleString()}</Text>
                    </Flex>
                    <Flex w="100%" mt="5" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Invest Now<Text ml="2"><i className="mdi mdi-currency-usd"></i></Text></>}</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}