import { useState, useEffect } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";



export default function DashNav({ setMenu, selected, setSelected }) {
    const history = useHistory()

    return (
        <Flex mt="12" direction="column">
            {
                ["Dashboard", "Trading", "Deposit", "Withdrawal", "Transfer", "KYC", "Profile", "Logout"].map((item, index) => (
                    <Flex w="100%" key={index} borderRadius=".5rem" bg={index === selected ? "rgba(40, 129, 119, .2)" : "transparent"} _hover={{ bg: "rgba(40, 129, 119, .2)" }} fontWeight={index === selected ? "600" : "400"} color={index === selected ? "#fff" : "#cecece"} transition="200ms ease-in-out" mb="4" p="2" cursor="pointer" align="center" onClick={() => {
                        if(index < 7) {
                            setSelected(index)
                            history.push(index === 0 ? "/dashboard/" : index === 1 ? "/dashboard/trading" : index === 2 ? "/dashboard/deposit" : index === 3 ? "/dashboard/withdrawal" : index === 4 ? "/dashboard/transfer" : index === 5 ? "/dashboard/kyc" : index === 6 && "/dashboard/profile")
                        }
                        else {
                            setMenu(true)
                        }
                    }}>
                        <Text fontSize="18px"><i className={index === 0 ? "mdi mdi-view-dashboard" : index === 1 ? "mdi mdi-lightbulb-on-outline" : index === 2 ? "mdi mdi-wallet" : index === 3 ? "mdi mdi-currency-usd" : index === 4 ? "mdi mdi-share" : index === 5 ? "mdi mdi-layers" : index === 6 ? "mdi mdi-account-plus" : "mdi mdi-logout-variant"}></i></Text>
                        <Text ml="2">{item}</Text>
                    </Flex>
                ))
            }
        </Flex>
    )
}