import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import { ApiFile } from "./request";
import logo from "./logo.png"
import {
	BrowserRouter as Router,
	Switch,
	Route,
} from "react-router-dom";
import ScrollToTop from "./sc";
import DashNav from "./dashNav";
import D1 from "./d1"
import D2 from "./d2";
import D3 from "./d3";
import D4 from "./d4";
import D5 from "./d5";
import D6 from "./d6";
import D7 from "./d7";
import DashMob from "./dashMob";



export default function Dashboard({ isLogged, setIsLogged }) {
    const history = useHistory()
    useEffect(() => {
        !isLogged && history.push("/login")
        !isLogged && Toast("You need to gain access to access dashbaord")
    }, [])

    const [selected, setSelected] = useState(0)
    const data = JSON.parse(sessionStorage.getItem("brixtenBasic"))
    const [brixtenData, setbrixtenData] = useState(data)
    const [menu, setMenu] = useState(false)
    const [menu2, setMenu2] = useState(false)
    const [menu3, setMenu3] = useState(false)
    const [bal1, setBal1] = useState(sessionStorage.getItem("brixtenBal1"))
    const [bal3, setBal3] = useState(sessionStorage.getItem("brixtenBal2"))
    const [bal2, setBal2] = useState(sessionStorage.getItem("brixtenBal3"))
    const [kyc, setKyc] = useState(sessionStorage.getItem("brixtenKy"))

    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")

    const [loading, setLoading] = useState(false)

    async function addData() {
        const a = ref1.current.value
        const b = ref2.current.value
        if(a !== "" && b !== "") {
            setLoading(true)
            const formData = new FormData()
            formData.append("uid9", brixtenData?.uid)
            formData.append("cpass", a)
            formData.append("npass", b)
            const res = await ApiFile("post", "loaduser.php", formData)
            if(res?.status === true) {
                Toast(res?.message, "success")
                setMenu2(false)
            }
            else {
                Toast(res.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Kindly fill in your current and new password you want", "error")
        }
	}


    async function addData2() {
        const a = ref3.current.value
        if(a !== "") {
            if(parseInt(a) <= parseInt(bal1)) {
                setLoading(true)
                const formData = new FormData()
                formData.append("uid10", brixtenData?.uid)
                formData.append("amount", a)
                const res = await ApiFile("post", "loaduser.php", formData)
                if(res?.status === true) {
                    sessionStorage.setItem("brixtenBal1", res.data.bal)
                    setBal1(res.data.bal)
                    sessionStorage.setItem("brixtenBal2", res.data.trading)
                    setBal3(res.data.trading)
                    Toast(res?.message, "success")
                    setMenu3(false)
                }
                else {
                    Toast(res.message, "error")
                }
                setLoading(false)
            }
            else {
                Toast("You don't have a sufficient balance to transfer!", "error")
            }
        }
        else {
            Toast("Kindly enter the amount you will like to trade with", "error")
        }
	}

    return (
        <>
            <Flex direction="column" w="100%">      
            <Router>				
                <ScrollToTop>
                    <Flex w="100%" direction={["column", "row"]}>
                        <Flex w="23%" display={["none", "flex"]} position="sticky" top="0" h="100vh" direction="column" px="5" py="5">
                            <Flex><Image src={logo} w="30px" h="30px" /></Flex>
                            <DashNav setMenu={setMenu} selected={selected} setSelected={setSelected} />
                        </Flex>
                        <Flex display={["flex", "none"]} w="100%"><DashMob setMenu={setMenu} selected={selected} setSelected={setSelected} /></Flex>

                        <Flex pb={["80px", "0"]} flex="1" minH="100vh" bg="#fff" color="#000" direction="column">
                            <Flex w="100%" bg="#fff" position="sticky" top="0" px={["5", "7"]} py={["3", "5"]} borderBottom="1px solid #e5e7eb" justify="space-between" zIndex="10">
                                <Flex align="center"><Text color="#fff" px="2" py="1" bg="#000" fontSize={["13px", "15px"]} cursor="pointer" onClick={() => history.push("/")}>BrixtenCove</Text></Flex>

                                <Flex color="#6b7280" align="center" cursor="pointer" onClick={() => {
                                    setMenu(true)
                                }}>
                                    <Text fontSize={["16px", "18px"]}><i className="mdi mdi-logout-variant"></i></Text> <Text ml="2" fontSize={["13px", "15px"]}>Logout</Text>
                                </Flex>
                            </Flex>

                            <Flex px={["5", "7" ]}py="8" flex="1" direction="column">
                                <Switch>
                                    <Route exact path="/dashboard"><D1 bal1={bal1} bal3={bal3} bal2={bal2} brixtenData={brixtenData} setMenu3={setMenu3} /></Route>
                                    <Route path="/dashboard/deposit"><D2 kyc={kyc} setBal1={setBal1} brixtenData={brixtenData} /></Route>
                                    <Route path="/dashboard/withdrawal"><D3 bal1={bal1} setBal1={setBal1} brixtenData={brixtenData} /></Route>
                                    <Route path="/dashboard/Transfer"><D4 bal1={bal1} setBal1={setBal1} brixtenData={brixtenData} /></Route>
                                    <Route path="/dashboard/profile"><D5 setMenu={setMenu2} brixtenData={brixtenData} setbrixtenData={setbrixtenData} /></Route>
                                    <Route path="/dashboard/trading"><D6 bal1={bal1} bal3={bal3} brixtenData={brixtenData} setMenu3={setMenu3} setBal3={setBal3} setBal2={setBal2} setbrixtenData={setbrixtenData} /></Route>
                                    <Route path="/dashboard/kyc"><D7 kyc={kyc} setKyc={setKyc} brixtenData={brixtenData} setbrixtenData={setbrixtenData} /></Route>
                                    <Route><D1  bal1={bal1} bal3={bal3} bal2={bal2} brixtenData={brixtenData} setMenu3={setMenu3} /></Route>
                                </Switch>
                            </Flex>
                        </Flex>
                    </Flex>
                </ScrollToTop>
            </Router>
            </Flex>
            

            {
                menu && 
                <Flex w="100%" position="fixed" top="0" left="0" height="100%" zIndex="100">
                    <Flex w="100%" h="100%" position="absolute" justify="center" align="center" top="0" left="0" bg="rgba(0,0,0,0.6)" backdropFilter="blur(5px)">
                        <Flex w={["90%", "600px"]} data-aos="fade-up" maxH="80vh" direction="column" bg="#fff" color="#000" borderRadius=".5rem" px="5" py="6">
                            <Flex w="100%" justify="flex-end">
                                <Flex borderRadius=".5rem" border="1px solid #cecece" justify="center" align="center" fontSize="20px" w="40px" h="40px" cursor="pointer" onClick={() => setMenu(false)}><i className="mdi mdi-close"></i></Flex>
                            </Flex>
                            <Text mt="2" pb="5" borderBottom="1px solid #cecece" fontWeight="600" textAlign="center">Are you sure you want to logout?</Text>
                            <Flex w="100%" direction="column" py="5">
                                <Flex w="100%" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => {
                                    sessionStorage.removeItem("brixtenisLogged")
                                    sessionStorage.removeItem("brixtenBasic")
                                    sessionStorage.removeItem("brixtenBal1")
                                    sessionStorage.removeItem("brixtenBal2")
                                    sessionStorage.removeItem("brixtenBal3")
                                    sessionStorage.removeItem("brixtenKy")
                                    setIsLogged(false)
                                    setbrixtenData(null)
                                    setMenu(false)
                                    history.push("/login")
                                }}>Logout <Text ml="2"><i className="mdi mdi-logout-variant"></i></Text></Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }


            {
                menu2 && 
                <Flex w="100%" position="fixed" top="0" left="0" height="100%" zIndex="100">
                    <Flex w="100%" h="100%" position="absolute" justify="center" align="center" top="0" left="0" bg="rgba(0,0,0,0.6)" backdropFilter="blur(5px)">
                        <Flex w={["90%", "600px"]} data-aos="fade-up" maxH="80vh" direction="column" bg="#fff" color="#000" borderRadius=".5rem" px="5" py="6">
                            <Flex w="100%" justify="flex-end">
                                <Flex borderRadius=".5rem" border="1px solid #cecece" justify="center" align="center" fontSize="20px" w="40px" h="40px" cursor="pointer" onClick={() => setMenu2(false)}><i className="mdi mdi-close"></i></Flex>
                            </Flex>
                            <Text mt="2" pb="5" mb="7" borderBottom="1px solid #cecece" fontWeight="600" textAlign="center">Change your password</Text>

                            <Text fontWeight="600" fontSize="13px" mb="1">Current Password</Text>
                            <Text w="100%"><input type="password" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your current password" ref={ref1} /></Text>

                            <Text mt="4" fontWeight="600" fontSize="13px" mb="1">New Password</Text>
                            <Text w="100%"><input type="password" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your new password" ref={ref2} /></Text>

                            <Flex w="100%" direction="column" py="5">
                                <Flex w="100%" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Change Password <Text ml="2"><i className="mdi mdi-lock"></i></Text></>}</Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }

            {
                menu3 && 
                <Flex w="100%" position="fixed" top="0" left="0" height="100%" zIndex="100">
                    <Flex w="100%" h="100%" position="absolute" justify="center" align="center" top="0" left="0" bg="rgba(0,0,0,0.6)" backdropFilter="blur(5px)">
                        <Flex w={["90%", "600px"]} data-aos="fade-up" maxH="80vh" direction="column" bg="#fff" color="#000" borderRadius=".5rem" px="5" py="6">
                            <Flex w="100%" justify="flex-end">
                                <Flex borderRadius=".5rem" border="1px solid #cecece" justify="center" align="center" fontSize="20px" w="40px" h="40px" cursor="pointer" onClick={() => setMenu3(false)}><i className="mdi mdi-close"></i></Flex>
                            </Flex>
                            <Text mt="2" pb="5" mb="7" borderBottom="1px solid #cecece" fontWeight="600" textAlign="center">Transfer to Trading Balance</Text>
                            <Text>Current Balance: <Text as="span" ml="2" fontWeight="700">${parseInt(bal1)?.toLocaleString()}.00</Text></Text>
                            <Text mt="4" fontWeight="600" fontSize="13px" mb="1">Amount to add</Text>
                            <Text w="100%"><input type="number" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter the amount you want to add in $" ref={ref3} /></Text>

                            <Flex w="100%" direction="column" py="5">
                                <Flex w="100%" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => addData2()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Add <Text ml="2"><i className="mdi mdi-plus"></i></Text></>}</Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            }
        </>
    )
}