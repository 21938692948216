import { useState, useEffect, useRef } from "react";
import { Flex, Text, Image, Spinner } from "@chakra-ui/react";
import { useHistory, useLocation } from "react-router-dom";
import Toast from "./toast";
import logo from "./logo.png"
import user from "./user.png"
import { ApiRequest } from "./request";



export default function Login({ isLogged, setIsLogged }) {
    const history = useHistory()
    useEffect(() => {
        isLogged && history.push("/dashboard")
    }, [])
    
    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")


    useEffect(() => {
        if(localStorage.getItem("brixtenUser1") !== null) {
            const a = localStorage.getItem("brixtenUser1")
            const b = localStorage.getItem("brixtenUser2")
            ref1.current.value = a
            ref2.current.value = b
            ref3.current.checked = true
        }
    }, [])
    const [showPass, setShowPass] = useState(false)

    const [loading, setLoading] = useState(false)

    async function gainAccess() {
        const a = ref1.current.value
        const b = ref2.current.value

        if(a !== "" && b !== "") {
            setLoading(true)
            const res = await ApiRequest("post", "login.php", {
                email: a,
                pass: b,
            })
            if(res?.status === true) {
                Toast(<Text textTransform="capitalize">{res.message}</Text>, "success")
                sessionStorage.setItem("brixtenisLogged", true)
                sessionStorage.setItem("brixtenBasic", JSON.stringify(res.data))
                sessionStorage.setItem("brixtenBal1", res.data?.bal)
                sessionStorage.setItem("brixtenBal2", res.data?.trading)
                sessionStorage.setItem("brixtenBal3", res.data?.profit)
                sessionStorage.setItem("brixtenKy", res.data?.kyc)
                setIsLogged(true)
                if(ref3.current.checked) {
                    localStorage.setItem("brixtenUser1", a)
                    localStorage.setItem("brixtenUser2", b)
                }
                else {
                    localStorage.removeItem("brixtenUser1")
                    localStorage.removeItem("brixtenUser2")
                }
                setTimeout(function() {
                    history.push("/dashboard")
                }, 500)
            }
            else {
                Toast(res?.message, "error")
            }
            setLoading(false)
        }
        else {
            Toast("Kindly fill all the input fields", "error")
        }
    }

    return (
        <Flex w="100%" direction="column" align="center" py="8">
            <Image src={logo} display={["none", "block"]} w="50px" data-aos="fade-down" />

            <Flex mt="7" w={["95%", "600px"]} borderRadius=".5rem" pt="10" pb="12" px={["4", "6"]} bg="rgba(40, 129, 119, .2)" direction="column" data-aos="fade-up">
                <Flex align="center">
                    <Image src={user} h="90px" />
                    <Text ml="3">
                        <Text fontSize="24px" fontWeight="600">Login</Text>
                        <Text color="#cecece" fontSize="13px">Kindly fill in your account credentials</Text>
                    </Text>
                </Flex>

                <Flex w="100%" mt="8" direction="column">
                    <Text color="#cecece" fontSize="13px" mb="1">Email</Text>
                    <Text w="100%"><input type="email" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your email" ref={ref1} /></Text>
                    
                    <Text color="#cecece" fontSize="13px" mb="1" mt="4">Password</Text>
                    <Flex w="100%" position="relative" align="center">
                        <Text w="100%"><input type="password" style={{ width: "100%", border: "1px solid #288177", borderRadius: ".5rem", padding: "12px 20px"}} placeholder="Enter your password" ref={ref2} /></Text>

                        <Text right="0" mr="3" cursor="pointer" position="absolute" zIndex="1" color="#000" onClick={() => {
                            if(showPass) {
                                setShowPass(false)
                                ref2.current.setAttribute("type", "password")
                            }
                            else {
                                setShowPass(true)
                                ref2.current.setAttribute("type", "text")
                            }
                        }}><i className={showPass ? "mdi mdi-eye-off" : "mdi mdi-eye"}></i></Text>
                    </Flex>

                    <Flex mt="5" justify="space-between" align="center">
                        <Flex align="center">
                            <input type="checkbox" ref={ref3} />
                            <Text ml="2" color="#cecece" fontSize="13px">Remember me</Text>
                        </Flex>
                        <Text fontSize={["13px", "15px"]} textDecoration="underline" color="#288177" ml="2" fontWeight="600" cursor="pointer" onClick={() => history.push("/forgot-password")}>Forgot Password?</Text>
                    </Flex>

                    <Flex mt="5" cursor="pointer" color="#fff" fontWeight="500" borderRadius=".5rem" bg="#288177" _hover={{ bg: "#48bdb0" }} transition="300ms ease-in-out" padding="12px 22px" justify="center" align="center" onClick={() => gainAccess()}>
                        {loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : <>Sign In <Text ml="2"><i className="mdi mdi-lock-open-outline"></i></Text></>}
                    </Flex>

                    <Text textAlign="center" fontSize={["13px", "15px"]} mt="7">Don't have an account? <Text as="span" color="#288177" ml="2" fontWeight="600" cursor="pointer" onClick={() => history.push("/register")}>Sign Up</Text></Text>
                </Flex>
            </Flex>
        </Flex>
    )
}